import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Stack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { IoIosCall, IoIosPin, IoIosMail } from 'react-icons/io'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import FormQuote from '../components/formQuote'
import Layout from '../components/layout'

import { Article, FeaturedBanner, Image, P, Section } from '../ui'

function ReservationsPage({ data }) {
  const { t } = useTranslation()

  const onSubmit = async (values, setSuccess) => {
    const data = {
      NAME: values.name,
      PHONE: values.phone,
      CHECKIN: values.date.toISOString(),
      TIME: values.time + ' (24 hrs format)',
    }

    const res = await addToMailchimp(
      values.email,
      data,
      'https://anayjose.us19.list-manage.com/subscribe/post?u=913dbc71926ff10619b39e45b&amp;id=c94b62c868'
    )

    if (res.result === 'success') setSuccess(true)
    else setSuccess(false)
  }

  return (
    <Layout title="Contacto" variantHeader="solid">
      <Article
        display="flex"
        flexDirection={{ base: 'column', lg: 'row' }}
        minHeight={{ lg: '100vh' }}
      >
        <Section fullWidth paddingTop="0" paddingBottom="0" flex="50%" spacing={6}>
          <FeaturedBanner
            height={{ base: '400px', lg: '100vh' }}
            image={<Image fluid={data.banner.childImageSharp.fluid} layout="background" />}
          />
        </Section>
        <Section
          flex="50%"
          backgroundColor="black"
          color="white"
          justifyContent="center"
          alignItems="center"
          layoutStyle={{ justifyContent: 'center' }}
          spacing={6}
          textAlign="center"
        >
          <FormQuote
            hideOnSuccess
            onSubmit={onSubmit}
            name={{
              label: t('common:form.name.label'),
              required: t('common:form.name.required'),
              invalid: t('common:form.name.invalid'),
            }}
            email={{
              required: t('common:form.email.required'),
              invalid: t('common:form.email.invalid'),
            }}
            date={{
              label: t('common:form.date.label'),
              required: t('common:form.date.required'),
              invalid: t('common:form.date.invalid'),
            }}
            phone={{
              label: t('common:form.phone.label'),
              required: t('common:form.phone.required'),
              invalid: t('common:form.phone.invalid'),
            }}
            time={{
              label: t('common:form.time.label'),
              required: t('common:form.time.required'),
              invalid: t('common:form.time.invalid'),
            }}
            successText={t('common:form.successText')}
            buttonText={t('common:form.sendMessage')}
          />
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "index/contacto-img.jpg" }) {
      ...bannerSectionImage
    }
  }
`

export default ReservationsPage
